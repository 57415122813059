import React from 'react'
import { StaticQuery, graphql } from "gatsby"

const Team = () => (
    <StaticQuery
    query={graphql`
    {
      allWpPage(filter: {slug: {eq: "about"}}) {
        nodes {
          aboutUs {
            team {
              ... on WpTeam {
                title
                  team {
                    designation
                  }
                  featuredImage {
                    node {
                      altText
                      mediaItemUrl
                      mediaDetails {
                        sizes {
                          sourceUrl
                        }
                      }
                    }
                  }
              }
            }
          }
        }
      }
      }
    `}
    render={data => {
        const pageContent = data.allWpPage.nodes[0].aboutUs.team;
        // console.log(pageContent);
        return (
            <div className="row">
                {pageContent && pageContent?.map((team, index) => (
                    <div className="col-sm-6 mt-135" data-aos="fade-up" data-aos-delay={index * 200} key={index}>
                        <div className="team-member">
                            {/* <StaticImage
                                src="../images/Nicola-Bond.png"
                                quality={100}
                                formats={["auto", "webp", "avif"]}
                                alt="signature"
                                className="w-100 img-fluid"
                            /> */}
                            {/* <img loading="lazy" src={team.featuredImage && team.featuredImage.node.mediaItemUrl} alt={team.featuredImage && team.featuredImage.node.altText} className="w-100 img-fluid gatsby-image-wrapper" /> */}
                            <picture>
                              <source media="(min-width:575px)" srcSet={team.featuredImage.node?.mediaItemUrl} />
                              <img loading="lazy" width="606" height="606" src={team.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={team.featuredImage.node?.altText} className="w-100 img-fluid gatsby-image-wrapper" />
                            </picture>

                            <h3 className="h3 text-capitalize">{team.title}</h3>
                            <p>{team.team.designation}</p>
                        </div>
                    </div>
                ))}                
            </div>
        );
    }}
    ></StaticQuery>
);

export default Team;

