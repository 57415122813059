import React from 'react'
import { StaticQuery, graphql } from "gatsby"

const AboutLocation = () => (
    <StaticQuery
        query={graphql`
          {
            allWpPage(filter: {slug: {eq: "global-page"}}) {
                nodes {
                  globalPage {
                    officeLocations {
                      officeLocation
                    }
                  }
                }
            }
          }
        `}
        render={data => {
          const pageContent = data.allWpPage.nodes[0].globalPage;

          return(
            <div className='section-growth overflow-hidden position-relative text-white py-100'>
                <div className="section-growth-overlay" style={{ width: '96%' }}></div>
                <div className='container'>
                    <div className='px-md-5'>
                        <div className='row'>
                            {pageContent?.officeLocations?.map((address, index) => (
                                <div className='col-sm-6 py-md-4 py-2' data-aos="fade-up" data-aos-delay={(index + 1) * 200} key={index}>
                                    <address className='location-adr' dangerouslySetInnerHTML={{__html: address.officeLocation }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
          )
        }}></StaticQuery>
)

export default AboutLocation;