import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Team from "../components/Team"
import AboutLocation from "../components/AboutLocation"

const AboutPage = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];

  
  const missionScroll = React.createRef();
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
    let offsetTop = missionScroll.current.offsetTop;
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > offsetTop - (window.innerHeight / 2.5))
    })
  }, [])

  
  
  return(
    <Layout>
      <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />    
      
      <div className="page-intro page-inner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-xl-9 col-xxl-10">
              <h1 className="title" dangerouslySetInnerHTML={{__html : pageContent?.aboutUs.title}} />
              
              <div dangerouslySetInnerHTML={{__html: pageContent?.content}} />
  
              <div className="text-end">
                <div className="signature">{pageContent?.aboutUs.nicolaSignature}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AboutLocation />
  
      <div className="py-120 pb-0">
        <div className="container">
          <div className="row pb-md-4">
            <div className="col-sm-8 col-md-6 mb-md-4 mb-2 col-xxl-5 col-lg-5" data-aos="fade-up" data-aos-delay="200">
              <h2 className="h1" dangerouslySetInnerHTML={{__html : pageContent?.aboutUs.meetTheHumanTitle}} />
            </div>
            <div className="col-md-11 col-lg-9 col-xxl-11" data-aos="fade-up" data-aos-delay="200">
              <p>{pageContent?.aboutUs.meetTheHumanSubtitle}</p>
            </div>
          </div>
  
          <Team />
        </div>
      </div>
  
      <div className="py-120 team-section text-dark overflow-hidden mt-5">
        <div className="container">
          <div className="row gx-5 align-items-center">
            <div className="col-md-4 mb-md-0 mb-4 pb-md-0 pb-2 wow fadeInLeft" data-aos-delay="100">
              <h2 className="h1" dangerouslySetInnerHTML={{__html : pageContent?.aboutUs.gurusTitle}} />
              <p>{pageContent?.aboutUs.gurusSubtitle}</p>
            </div>
            <div className="col-md-8 wow fadeInRight" data-aos-delay="200">
                {/* <img loading="lazy" src={pageContent?.aboutUs.gurusSectionImage.mediaItemUrl} alt={pageContent?.aboutUs.gurusSectionImage.altText} className="w-100 img-fluid gatsby-image-wrapper" /> */}
                <picture>
                  <source media="(min-width:992px)" srcSet={pageContent?.aboutUs.gurusSectionImage?.mediaItemUrl} />
                  <source media="(min-width:575px)" srcSet={pageContent?.aboutUs.gurusSectionImage?.mediaDetails.sizes[2].sourceUrl} />
                  <img loading="lazy" width="808" height="595" src={pageContent?.aboutUs.gurusSectionImage?.mediaDetails.sizes[0].sourceUrl} alt={pageContent?.aboutUs.gurusSectionImage?.altText} className="w-100 img-fluid gatsby-image-wrapper" />
                </picture>
            </div>
          </div>
        </div>
      </div>
  
      <div ref={missionScroll} className="section-growth our-mission overflow-hidden position-relative text-white py-120">
        <div className="section-growth-overlay" style={{ width: scroll ? '100%' : '96%' }}></div>
          <div className="container">
            <div className="row mb-5 pb-md-4" data-aos="fade-up" data-aos-delay="200">
              <div className="col-sm-8 col-md-6 mb-4 col-xxl-8 col-lg-7">
                {/* <h1>Our <em>Mission.</em></h1> */}
                <h2 className="h1"  dangerouslySetInnerHTML={{__html : pageContent?.aboutUs.ourMissionTitle}} />
              </div>
              <div className="col-md-12 col-lg-10 col-xxl-10">
                <p>{pageContent?.aboutUs.ourMissionSubtitle}</p>
                <p>{pageContent?.aboutUs.ourMissionContent}</p>
              </div>
            </div>
  
            <div className="text-end pb-md-5 pb-2 pt-md-5">
              <div className="mission-image position-relative">
                <div data-aos="zoom-in-up" data-aos-delay="200">
                  {/* <img loading="lazy" src={pageContent?.aboutUs.ourMissionImage.mediaItemUrl} alt={pageContent?.aboutUs.ourMissionImage.altText} className="img-fluid gatsby-image-wrapper" /> */}
                  <picture>
                    <source media="(min-width:992px)" srcSet={pageContent?.aboutUs.ourMissionImage?.mediaItemUrl} />
                    <source media="(min-width:480px)" srcSet={pageContent?.aboutUs.ourMissionImage?.mediaDetails.sizes[2].sourceUrl} />
                    <img loading="lazy" width="1000" height="610" src={pageContent?.aboutUs.ourMissionImage?.mediaDetails.sizes[0].sourceUrl} alt={pageContent?.aboutUs.ourMissionImage?.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      <div className="py-120">
        <div className="container" data-aos="fade-in" data-aos-delay="300">
          <p className="lead-lg" dangerouslySetInnerHTML={{__html: pageContent?.aboutUs.footerAboveSectionContent}} />
        </div>
      </div>
    
    </Layout>
  )
}

export default AboutPage

export const query = graphql`{
  allWpPage(filter: {slug: {eq: "about"}}) {
    nodes {
      seo {
        title
        metaDesc
        focuskw
      }
      aboutUs {
        footerAboveSectionContent
        gurusSubtitle
        gurusTitle
        meetTheHumanSubtitle
        meetTheHumanTitle
        ourMissionSubtitle
        ourMissionContent
        ourMissionTitle
        title
        gurusSectionImage {
          altText
          mediaItemUrl
          mediaDetails {
            sizes {
              sourceUrl
            }
          }
        }
        ourMissionImage {
          altText
          mediaItemUrl
          mediaDetails {
            sizes {
              sourceUrl
            }
          }
        }
        nicolaSignature
      }
      content
    }
  }
}`